const news = [
  {
    title: "Molex Foundation Africa Visits Royal Seed Home in Kasoa",

    content: `<p>
On 7th October 2024, a team of 16 representatives from Molex Foundation Africa, including two
board members and Alhaji Yusif Braimah, visited Royal Seed Home in Kasoa. The home is a
charitable institution dedicated to supporting vulnerable children, including orphans and those
with physical disabilities. The purpose of the visit was to conduct a needs assessment, focusing
on how Molex Foundation Africa could support the home by addressing infrastructure,
resources, and improving overall conditions for the children.                                    </p><p>
Upon arrival, the team was warmly welcomed by Nana, the CEO of Royal Seed Home, who
shared her inspiring personal story. Nana explained that her own experience of living on the
streets motivated her to create a safe haven for vulnerable children. Her reflection on her
journey”. While I was living on the street, Royal Seed came to mind&quot;, set the tone for a heartfelt
and insightful discussion on the challenges and aspirations of the home.                                            </p><p>
One of the critical issues discussed during the visit was the water supply. The home relies on
borehole water, which is salty and untreated, as well as rainwater for daily needs. Although they
have filtration machines to treat the water, frequent power outages make the system unreliable,
forcing the home to rely on sachet water for basic needs like cooking and cleaning. The
personnel managing the water system explained the difficulties of manually operating the
filtration and pumping machines, describing the process as labor-intensive and time-consuming.                                            </p><p>
In response to these challenges, Dr. Essien, representing Molex Foundation Africa, assured the
home that the foundation would assist in building an automated water pumping system. This
system would ensure that water is pumped into storage tanks without manual supervision.
Steven, the I.T. Director at Molex, explained that the system would use sensors to monitor water
levels and automatically activate the pump, reducing the burden on the staff. He also mentioned
the possibility of integrating solar power to address the power shortages.                                            </p><p>
Education was another major focus of the visit. A teacher from the Royal Seed School proudly
shared that ten students had successfully completed their Senior Secondary School education.
However, there are concerns about financial difficulties preventing them from pursuing tertiary
education. One student passionately expressed her desire to attend university, highlighting the
importance of continuing her education. Molex Foundation Africa recognized the need for

educational support and pledged to explore partnerships with educational institutions to help
these students achieve their dreams.                                             </p><p>
The healthcare situation at Royal Seed Home also drew attention. The team toured the clinic,
where Miss Eva, an unprofessional nurse, provides basic medical care for the children. She
explained that while she had learned some nursing skills from visiting professionals, serious
medical cases often had to be referred to a distant public hospital. The foundation acknowledged
the need for better healthcare facilities and professional medical support to improve the services
provided at the clinic.                             </p><p>
Nana also spoke about the unfinished buildings on the premises, which could help alleviate
overcrowding and improve living conditions for the children if completed. However, financial
constraints have halted construction, and the home is in urgent need of support to complete these
projects. Molex Foundation Africa acknowledged this need and will explore ways to help finish
the infrastructure.                                             </p><p>
                                             
To address the water supply issue, the foundation proposed two solutions: the installation of an
automated water pumping system powered by renewable energy, such as solar, and the creation
of an underground runoff water collection system. This system would capture excess rainwater
from rooftops, store it in a backup tank, and ensure efficient distribution, especially for drinking
water.                                             
                                              </p><p>
To address the water supply issue, the foundation proposed two solutions: the installation of an
automated water pumping system powered by renewable energy, such as solar, and the creation
of an underground runoff water collection system. This system would capture excess rainwater
from rooftops, store it in a backup tank, and ensure efficient distribution, especially for drinking
water.                                             </p><p>
                                              
The visit to Royal Seed Home was both inspiring and informative. Nana and her team have done
remarkable work in caring for vulnerable children despite the many challenges they face. We at
Molex Foundation Africa are committed to doing all we can, but we cannot do it alone. We urge
businesses, foundations, and individuals with the means to contribute toward making a lasting
impact on the lives of these children. Your support, whether through financial contributions,
resources, or partnerships, can help transform the lives of the children at Royal Seed Home and
give them the opportunity for a brighter future. Together, we can make a meaningful difference.
Let us join hands to uplift Royal Seed Home and provide these children with the safe, nurturing
environment they need to thrive.                                             </p><p>
                                            `,
    author: "",
    date: "10-12-2024",
    pictures: [
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2Froyal%20seed%2FWhatsApp%20Image%202024-10-30%20at%2018.58.29%20(1).jpeg?alt=media&token=450b48d5-e2fc-4279-af3e-65a33d4f0f18",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2Froyal%20seed%2FWhatsApp%20Image%202024-10-30%20at%2018.58.28.jpeg?alt=media&token=d16bee95-aead-4e50-a633-a8888dee0d09",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2Froyal%20seed%2FWhatsApp%20Image%202024-10-30%20at%2018.58.27.jpeg?alt=media&token=dc431df0-c37c-460d-a9a3-4b24f05cf74d",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2Froyal%20seed%2FWhatsApp%20Image%202024-10-30%20at%2018.58.25.jpeg?alt=media&token=8856c9b1-a715-4cff-b571-46bc7481e0b9",
        "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2Froyal%20seed%2FWhatsApp%20Image%202024-10-30%20at%2018.58.29.jpeg?alt=media&token=11e8e00e-ddff-4147-b3a7-7e67a954d36a",
        "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2Froyal%20seed%2FWhatsApp%20Image%202024-10-30%20at%2018.58.30%20(1).jpeg?alt=media&token=c55948e1-313f-42be-bebe-2d5f59b61513",
        "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2Froyal%20seed%2FWhatsApp%20Image%202024-10-30%20at%2018.58.30.jpeg?alt=media&token=cf3b1b66-62b6-4745-aa0c-2b42927f1246",
        "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2Froyal%20seed%2FWhatsApp%20Image%202024-10-30%20at%2018.58.31.jpeg?alt=media&token=73dcffb6-b1f1-48dc-8189-0ab563caae28"
    ],
  },

  {
    title: "GUIDING STARS AT ABREM ESSIAM AME ZION BASIC SCHOOL",

    content: `<p>
                            On July 2, 2024, Molex Foundation Africa organized its Guiding Stars Program at Abrem Essiam AME Zion Basic School, located in the Komenda-Edina Eguafo Abrem District of Ghana's Central Region. The event focused on empowering young people, especially those in rural areas. The goal was to help them realize their potential and become positive people in their communities.
                                    </p><p>
                            The program targeted junior and senior high school students facing economic challenges. It offered mentorship, guidance, and life skills training to foster personal growth and boost confidence.
                                            </p><p>
                            The program kicked off with Miss Charlotte from Molex Foundation Africa explaining the program's mission and welcoming guests. Dr. Patrick Essien, CEO of the foundation, gave an inspiring speech from his own experiences. He stressed on perseverance and the impact of teachers as role models on his journey to success.
                                            </p><p>
                            Dr. Francis Nkrumah, a board member, engaged students in a survey about their dreams, and encouraged them to pursue their goals with determination and hard work.
                                            </p><p>
                             Miss Hawa Jibril from the foundation's UCC Chapter shared her personal story of overcoming challenges. she inspired the students to stay strong in their pursuit of education and personal growth.
                                             </p><p>
                            After the event, the students expressed gratitude to the Molex Foundation, saying the Guiding Stars Program had changed their outlook on the future. It boosted their confidence, reignited their passion, and helped them believe in their abilities to achieve their dreams and contribute positively to their communities.                 </p>
                                            `,
    author: "",
    date: "17-08-2024",
    pictures: [
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2FGallery%2F05.jpeg?alt=media&token=c0fb4493-f0f7-49c9-9265-ad84134941ed",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2FGallery%2F02.jpeg?alt=media&token=4f385686-7336-4d84-899b-9b83b1570dd9",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2FGallery%2F04.jpeg?alt=media&token=29c6f0a7-7dc9-4c14-a431-1f7858af60d6",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2FGallery%2F03.jpeg?alt=media&token=6e1cea1e-c1eb-41ed-ab43-e0a198718565",
      //   "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2FGallery%2F01.jpeg?alt=media&token=50474aee-21e2-41a3-84f4-7975f6e0638f",
      //   "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Frobo_training%2F3M3A3814.JPG?alt=media&token=79148932-4857-4183-9b42-283e49af22c4",
    ],
  },
  {
    title: "Recap of our Four-Day Robotics and AI Facilitators Training",
    content: `<p>
    Imagine a world where young girls find joy in creating smart machines that transform industries and improve lives. Motivated by this vision, the Molex Foundation's SHEROES in STEM organized a detailed four-day facilitators training starting on June 26, 2024, for our Robotics and AI Camp initiative.
            </p><p>
    Led by Dr. Kwadwo Anokye Dompreh, a senior lecturer at the Department of Physics, University of Cape Coast, and a STEM Educator, the training focused on learning technologies. We studied how these technologies help in effective learning, addressed study obstacles, and built a strong foundation for the days ahead.
                    </p><p>
    Day two increased our knowledge with practical exercises and hands-on activities. We deepened our understanding of key concepts and discussed the importance of learning technologies and how to overcome study barriers.
                    </p><p>
    Day three was dedicated to overcoming learning obstacles. We analyzed texts, identified key words, and explored their meanings. We also worked with robotics kits to strengthened our understanding through hands-on practice.
                    </p><p>
    On the final day, we delved into the Arduino Uno board, learning about its parts, connections, and programming. Dr. Dompreh shared his personal projects, encouraging us to follow suit.
                    </p><p>
    Overall, the four-day training was educational and empowering. It equipped us with the knowledge and confidence to excel in robotics, preparing us for future challenges and innovations. Molex Foundation's SHEROES in STEM is shaping a future where young girls are inspired to explore robotics and AI making way for innovation and progress.
                    </p>
                    `,
    author: "",
    date: "16-08-2024",
    pictures: [
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Frobo_training%2F3M3A3685.JPG?alt=media&token=32585132-1c3d-45d2-b8fd-2fc2966279ab",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Frobo_training%2F3M3A3684.JPG?alt=media&token=dc37e09f-fe48-4ad6-a580-ad1fed37ddd7",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Frobo_training%2F3M3A3682.JPG?alt=media&token=62138d65-f808-4033-a16b-4c19ddb83e65",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Frobo_training%2F3M3A3680.JPG?alt=media&token=23a1edd9-dcab-4968-a347-54fc086fdc77",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Frobo_training%2F3M3A3733.JPG?alt=media&token=14703638-2ab0-4db4-a51f-8b53c972c5fa",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Frobo_training%2F3M3A3814.JPG?alt=media&token=79148932-4857-4183-9b42-283e49af22c4",
    ],
  },
  {
    title:
      "Ending the Stigma Around Menstruation and Promoting Access to Menstrual Products",
    content: `<p>
        Menstruation, a natural aspect of life, continues to be overshadowed by silence and stigma. Addressing this issue head-on, the Molex Foundation Africa has embarked on a mission to dismantle the stigma surrounding menstruation while providing crucial support through the distribution of sanitary <pads class=""></pads>
</p><p>
Our commitment culminated in the "Menstrual Hygiene Day Celebration," an event dedicated to education, support, and the promotion of a stigma-free society. The foundation's primary objective is to empower girls and communities to embrace menstruation as a natural and normal part of life.
        </p><p>
During the event held in Nyanfeku Ekroful, Central region of Ghana, Dr. Patrick Essien, CEO of Molex Foundation Africa, emphasized, "Menstruation is not something to be ashamed of; in fact, none of us would be here if it weren't for menstruation." He urged parents to prioritize the well-being of their menstruating daughters and encouraged them to overcome the shame and discomfort associated with it.
        </p><p>
Dr. Essien also addressed the audience, appealing to men to play an active role in supporting girls during menstruation. He underscored the responsibility of men to protect young girls from exploitation and to create a supportive environment.
        </p><p>
He further encouraged young girls to openly discuss their menstruation experiences with parents, teachers, and healthcare professionals, viewing it as a crucial step towards self-care. Proper hygiene practices, such as changing sanitary pads twice daily and maintaining regular bathing routines, were emphasized as essential during menstruation.
        </p><p>
Additionally, the district director of the Ghana Health Service delivered a comprehensive educational session. She demonstrated the proper use and disposal of sanitary pads, stressing the importance of maintaining personal hygiene. Moreover, she highlighted the significance of a nutritious diet for overall well-being, advising against alcohol and caffeine consumption, and promoting abstinence from sexual activities to prevent unintended pregnancies.
        </p><p>
Professor Nana Ama Browne Klutse, head of the Physics Department at the University of Ghana and a native of Nyanfeku Ekroful, encouraged girls to remain focused and dedicated to achieving their dreams despite challenges. Her words resonated deeply, inspiring young attendees to strive for academic and personal excellence.
        </p><p>
To ensure menstrual hygiene support reached a broad audience, sanitary pads were generously distributed to over eight hundred students, with each receiving eight packs capable of lasting eight months. Teachers and other young girls in attendance were also beneficiaries of this initiative.
        </p><p>
This event not only provided tangible support but also fostered a community-wide dialogue aimed at breaking down barriers and promoting menstrual health and dignity. The Molex Foundation Africa remains committed to advancing these efforts, striving for a future where menstruation is embraced openly and supported universally.
        </p>
        `,
    author: "",
    date: "29-02-2024",
    pictures: [
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2Fmhd-ekuroful%2F3M3A1757.jpg?alt=media&token=64daf3ae-d30a-49a3-85e6-80307c649515",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2Fmhd-ekuroful%2F3M3A1672%20-%20Copy.jpg?alt=media&token=55b7f925-1886-4704-88c3-4ec116bc35d8",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2Fmhd-ekuroful%2F3M3A1681.jpg?alt=media&token=24729474-ca61-440e-a85b-85e2a6dade5f",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2Fmhd-ekuroful%2F3M3A1715.jpg?alt=media&token=c29f7afd-a042-423b-986c-8b670c3f778d",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2Fmhd-ekuroful%2F3M3A1858.jpg?alt=media&token=a55e913d-820b-4ef3-8833-9d5a54f12e42",
      // "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2FWhatsApp%20Image%202024-04-27%20at%203.08.10%20PM.jpeg?alt=media&token=33325ec6-fa45-4ee9-a5fd-dd2b4cfbfa71"
    ],
  },
  {
    title:
      "Celebrating Gender Diversity in STEM: A Milestone with the Launch of SHEROES",
    content: `<p>
        On Friday, 26th April, a historic event took place at the University of Cape Coast SMS Auditorium, marking a significant milestone in the promotion of gender diversity in STEM fields. The launch of SHEROES (Supporting Heroines in STEM Education and Research Opportunities) by Molex Foundation Africa brought together students from senior and junior high schools in the Central region, as well as those from Cape Coast Technical University and University of Cape Coast. It was a momentous occasion that underscored the importance of empowering women in science, technology, engineering, and mathematics (STEM).
</p><p>
        The event kicked off with an electrifying atmosphere, as students, educators, industry professionals, and community leaders gathered to witness the launch of SHEROES. The auditorium buzzed with excitement and anticipation as the program unfolded, highlighting the transformative impact that this initiative aims to achieve.
        </p><p>
        Dr. Regina Esi Turkson, a renowned Computer Scientist and lecturer at UCC, delivered a captivating keynote address, emphasizing the critical role of diversity in driving innovation and problem-solving in STEM fields. Her inspiring words set the tone for the event, encouraging young women to pursue their passions and break barriers in traditionally male-dominated fields.
        </p><p>
        Ms. Adelaide Asantewaa Asante, Head of the UK Hydrology and Ecology Center in West Africa, echoed Dr. Turkson's sentiments, emphasizing the importance of initiatives like SHEROES in addressing the underrepresentation of women in STEM. Her insights shed light on the challenges faced by women in STEM fields and underscored the need for concerted efforts to empower and support aspiring female scientists and engineers.
        </p><p>
        As the program drew to a close, Prof. Nana Ama Browne Klutse, Head of the Physics Department at the University of Ghana and Vice Chair of the IPCC, delivered closing remarks that resonated with the audience. She highlighted the transformative potential of SHEROES in revolutionizing our approach to technological development and expressed gratitude to Molex Foundation Africa for spearheading such a groundbreaking initiative.
        </p><p>
        Dr. Patrick Essien, the CEO of Molex Foundation Africa, also addressed the audience, reaffirming the organization's commitment to empowering young women in STEM. He announced plans for a two-week camp to train selected girls in artificial intelligence and robotics at UCC, further demonstrating Molex Foundation Africa's dedication to providing opportunities for skill development and career advancement in STEM fields.
        </p><p>
        Overall, the launch of SHEROES was a resounding success, showcasing the collective effort to promote gender diversity and inclusivity in STEM. It served as a reminder of the untapped potential of women in these fields and underscored the importance of creating an enabling environment where they can thrive and succeed. As we celebrate this milestone, let us continue to support and empower the next generation of female scientists, engineers, and innovators, paving the way for a brighter and more inclusive future in STEM.
        </p>
        `,
    author: "",
    date: "29-02-2024",
    pictures: [
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2FWhatsApp%20Image%202024-04-27%20at%203.08.08%20PM%20(1).jpeg?alt=media&token=81199a81-36e3-49c9-996a-8dbeae511d7e",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2FWhatsApp%20Image%202024-04-27%20at%203.08.08%20PM.jpeg?alt=media&token=ab3120a7-0106-44ba-9eb8-0bf116d34bea",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2FWhatsApp%20Image%202024-04-27%20at%203.08.09%20PM%20(1).jpeg?alt=media&token=296fc019-f15c-4d21-a268-278ad16f90de",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2FWhatsApp%20Image%202024-04-27%20at%203.08.09%20PM.jpeg?alt=media&token=c989ac83-39cd-4ca3-9000-8d522e615a37",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2FWhatsApp%20Image%202024-04-27%20at%203.08.10%20PM%20(1).jpeg?alt=media&token=c867dcac-3f07-49eb-ba25-dde33e098efd",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2FWhatsApp%20Image%202024-04-27%20at%203.08.10%20PM.jpeg?alt=media&token=33325ec6-fa45-4ee9-a5fd-dd2b4cfbfa71",
    ],
  },

  {
    title:
      "Empowering Dreams: Lawyer Nicholas Kwasi Appiah's Journey and the Impact of the GUIDING STARS Program",
    content: `Lawyer Nicholas Kwasi Appiah's journey from St. Augustine's College to the University of Ghana School of Law, School of Law is indeed an inspiring tale of perseverance and determination. 
<p/>
        His participation in the Molex Foundation's GUIDING STARS program at Sowadin JHS serves as a beacon of hope for JHS students, showcasing that regardless of one's background or challenges faced, it is possible to achieve one's dreams through hard work and dedication.
        <p/>
        By sharing his personal story with the students, Lawyer Nicholas Kwasi Appiah, does not only impart valuable lessons but also instill a sense of belief in the potential of each student to becoming future leaders in their own right. 
        <p/>
        His encouragement to unleash their full potential resonates deeply, emphasizing the importance of self-belief and resilience in the pursuit of success.
        
        Molex Foundation's GUIDING STARS program play a crucial role in nurturing the aspirations of young minds and providing them with the guidance and support they need to excel academically and beyond. 
        
        Through initiatives that celebrate education and inspire greatness, we pave the way for a brighter future filled with capable and empowered leaders.
        `,
    author: "Lawyer Nicholas Kwasi Appiah",
    date: "29-02-2024",
    pictures: [
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2F434485350_423507380361617_3334670885765537917_n.jpg?alt=media&token=de8b6882-cf17-4706-bd8b-a666beb5539c",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2F434499641_423507360361619_6626665407129560347_n.jpg?alt=media&token=c2360853-0cd0-47cb-8bf6-836a098208ba",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2F434507433_423507383694950_5958697922405551987_n.jpg?alt=media&token=04aea715-fb3e-4968-a73e-992926cb4592",
    ],
  },
  {
    title: "Miss Hawa's Inspiring Message to Pedu MA JHS Students",
    content: `Miss Hawa, an MPhil student in Physics and former teaching assistant at the University of Cape Coast, provided invaluable advice to the Junior High School students of Pedu MA JHS during the GUIDING STARS program. She emphasized the importance of prioritizing education and dedicating oneself to learning diligently, regardless of financial constraints. Drawing from her own experience on how she got Mastercard scholarship at Mfantseman Girls and subsequently University of Cape Coast, Miss Hawa encouraged the students to focus on their studies, assuring them that hard work opens doors to numerous opportunities. Additionally, she addressed the female students specifically, highlighting the need to avoid behaviors that could derail their dreams, such as engaging in sexual immorality. Her words served as a powerful reminder of the importance of determination, discipline, and personal integrity in achieving success.
        `,
    author: "",
    date: "13-01-2024",
    pictures: [
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2F430122996_406973932014962_7762418728390862674_n.jpg?alt=media&token=097ab257-186a-49b4-acdd-0d52a6a7f1a1",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2F429787059_406973968681625_7913203331794955858_n.jpg?alt=media&token=4aeebafc-ca8d-42c6-9227-71904b7d8d82",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2F3M3A0167.jpg?alt=media&token=68ea2e82-d75c-4189-ab38-9c71d16fae79",
    ],
  },
  {
    title: "Closing the Gender Gap: Encouraging Girls to Pursue Coding Careers",
    content: `<p>In today's rapidly evolving digital landscape, coding has become a fundamental skill that opens doors to countless opportunities in the tech industry. However, there remains a significant gender gap in this field, with women and girls underrepresented in coding and related careers. To foster diversity and innovation in technology, it's crucial to encourage girls to pursue coding careers. This article delves into the importance of closing the gender gap in coding, emphasizing the reasons why girls should consider coding careers and the role of coding in entrepreneurship in developing countries.
<p/><p>
        Encouraging girls to code is about more than just closing the gender gap. It's about fostering innovation and diversity in the tech industry, which ultimately leads to enhanced creativity and more comprehensive problem-solving. Different perspectives and experiences are essential in creating fresh ideas and innovative solutions.
        <p/>
 
        Furthermore, coding is a versatile skill that empowers individuals to create, problem-solve, and effect change. It extends beyond the realm of coding itself, developing problem-solving skills that are invaluable in various aspects of life, both personally and professionally. The ability to tackle complex challenges is a crucial skill, and coding helps nurture it.
        <p/>
        
        Coding not only offers a career path but also serves as a route to financial independence. Tech careers often provide competitive salaries, offering women and girls opportunities to narrow the gender pay gap and achieve financial self-sufficiency.
        <p/>
        
        Coding not only offers a career path but also serves as a route to financial independence. Tech careers often provide competitive salaries, offering women and girls opportunities to narrow the gender pay gap and achieve financial self-sufficiency.
        <p/>
        
        The statement, "If you learn how to code, you can build a career that fits your life," holds true. Coding professions often offer flexibility, allowing individuals to work remotely, freelance, or choose part-time roles. This flexibility enables women to balance their work and personal lives effectively, promoting a better work-life balance.
        <p/>
        
        In developing countries, coding plays a pivotal role in entrepreneurship. It offers a low-entry barrier, allowing aspiring entrepreneurs to create digital solutions with minimal initial investment. This reduces barriers to entry, enabling individuals in developing countries to compete on a global scale.
        <p/>
        
        Moreover, technology startups not only benefit the founders but also create jobs in local communities. This spurs economic development and reduces unemployment rates, contributing to the overall growth and prosperity of developing nations. Coding empowers individuals to take control of their economic destinies, driving positive change and progress in their communities.
        <p/>
        
        In conclusion, closing the gender gap in coding is not only a matter of equity but also an essential step toward a more inclusive and innovative tech industry. By encouraging girls to pursue coding careers, we can tap into a vast pool of talent and ensure that the future of technology is shaped by a diverse group of voices. Additionally, coding is a potent catalyst for entrepreneurship in developing countries, offering individuals the tools to address local challenges, create economic opportunities, and drive progress. Coding is more than a skill; it's a pathway to a brighter, more inclusive, and entrepreneurial future.
        `,
    author: "Stephen Nyankson",
    date: "12-03-2024",
    pictures: [
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2Fafrican-american-business-woman-working-computer_1303-9873%20(1).jpg?alt=media&token=721c3401-9f4b-4266-bc50-d5f589c45c6a",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2Fblack-girl-summer-city-with-laptop_1157-38002.jpg?alt=media&token=d0e1894d-f446-4ffc-9d81-c4aa654b371c",
      "https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fnews%2Ffuturistic-business-scene-with-ultra-modern-ambiance_23-2151003798.jpg?alt=media&token=11ea5d30-8ef6-4e31-b7c2-2d84700df815",
    ],
  },
];

export default news;
