import React from "react";
import { Link } from "react-router-dom"; // Import Link component
import Header from "./Header";
import Footer from "./Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./css/Home.css";
import news from "../data/news";



export default function News() {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <Header />

      <section className="contact container mt-5 section-padding">
        <div className="container-fluid row article-container">

        <div className=" col-lg-4 border-bottkom mb-4">
              <div className="row borderj column col-12 ms-auto text-center p-4 rounded" style={{display: 'flex', justifyContent: 'stretch', alignItems: 'stretch' }}>
                <img src="https://cdn.modernghana.com/content/300/360/1022202434830-wbreuigtto-dominic-ebow-arhin.jpg"/>
                <div className="column col-12">
                  <div className="col-12 vision-text contact-infoo">
                    <div className="article-title" style={{fontWeight: 'bold'}}>Impact Of Illegal Mining (galamsey) On Local Ecosystems And Climate Resilience In Ghana</div>
                    <div></div>
                    {/*<div className="text-danger">09/05/2024</div>*/}
                  </div>
                </div>
                {/* Pass the title as a URL parameter */}
                <Link to='https://www.modernghana.com/news/1350889/impact-of-illegal-mining-galamsey-on-local-ecosy.html#google_vignette' className="col-12 text-danger learn-more">Learn more <em className="mdi mdi-arrow-right-thin"></em></Link>
              </div>
            </div>

          {news.map((item, index) => (
            <div key={index} className="row col-lg-4 border-bottkom mb-4">
              <div className="row bordero col-12 text-center ms-auto  rounded" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <img src={item.pictures[0]}/>
                <div className="column col-12">
                  <div className="col-12 vision-text contact-infoo">
                    <div className="article-title" style={{fontWeight: 'bold'}}>{item.title}</div>
                    {/*<div>{item.author}</div>*/}
                    {/*<div className="text-danger">{item.date}</div>*/}
                  </div>
                </div>
                {/* Pass the title as a URL parameter */}
                <Link to={`/news-detail/${encodeURIComponent(item.title)}`} className="col-12 text-danger learn-more p-2">Learn more <em className="mdi mdi-arrow-right-thin"></em></Link>
              </div>
            </div>
          ))}
            <div className=" col-lg-4 border-bottkom mb-4">
              <div className="row borderj column col-12 ms-auto text-center p-4 rounded" style={{display: 'flex', justifyContent: 'stretch', alignItems: 'stretch' }}>
                <img src="images/prog/2A1A1399.jpg"/>
                <div className="column col-12">
                  <div className="col-12 vision-text contact-infoo">
                    <div className="article-title" style={{fontWeight: 'bold'}}>Molex Foundation Africa Launches SHEROSE in STEM</div>
                    <div></div>
                    {/*<div className="text-danger">09/05/2024</div>*/}
                  </div>
                </div>
                {/* Pass the title as a URL parameter */}
                <Link to='https://thechronicle.com.gh/molex-foundation-africa-launches-sherose-in-stem/' className="col-12 text-danger learn-more">Learn more <em className="mdi mdi-arrow-right-thin"></em></Link>
              </div>
            </div>

            <div className="row col-lg-4 border-bottkom mb-4">
              <div className="row borderj col-12 text-center ms-auto p-4 rounded"
                   style={{justifyContent: 'space-between', alignItems: 'center'}}>
                <img src="https://bunny-wp-pullzone-vufvp0bs6b.b-cdn.net/wp-content/uploads/2024/04/Dr-Patrick-Essien-centre-lead-696x451.jpg"/>
                <div className="column col-12">
                  <div className="col-12 vision-text contact-infoo">
                    <div className="article-title" style={{fontWeight: 'bold'}}>Our education system requires a paradigm shift -Cape Vars Don</div>
                    <div></div>
                    {/*<div className="text-danger">30/04/2024</div>*/}
                  </div>
                </div>
                {/* Pass the title as a URL parameter */}
                <Link to='https://thechronicle.com.gh/our-education-system-requires-a-paradigm-shift-cape-vars-don/#'
                      className="col-12 text-danger learn-more">Learn more <em
                    className="mdi mdi-arrow-right-thin"></em></Link>
              </div>
            </div>
        </div>
      </section>
      <Footer/>
    </div>
  );
}
