import { React, useEffect, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";

import Header from "./Header";
import Footer from "./Footer";
import Bannar from "./Bannar";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./css/Home.css";
import "slick-carousel/slick/slick-theme.css";
import YouTubeEmbed from "./YouTube";

function NavItem({ id, text, active }) {
  return (
    <li className="nav-item" role="presentation">
      <button
        className={`nav-link${active ? " active" : ""}`}
        id={id}
        data-bs-toggle="pill"
        data-bs-target={`#${id}`}
        type="button"
        role="tab"
        aria-controls={id}
        aria-selected={active}
      >
        {text}
      </button>
    </li>
  );
}

function TabContent({ id, active, children }) {
  return (
    <div
      className={`tab-pane fade${active ? " show active" : ""}`}
      id={id}
      role="tabpanel"
      aria-labelledby={`${id}-tab`}
    >
      {children}
    </div>
  );
}

export default function Home() {
  const missionRef = useRef(null);
  const visionRef = useRef(null);
  const [countdown, setCountdown] = useState(0);
  const coreValuesRef = useRef(null);
  const [show, setShow] = useState(true);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const [showToast, setShowToast] = useState(true);

  const handleClose = () => {
    setShowToast(false);
  };

  const opts = {
    height: "300",
    width: "400",
    playerVars: {
      autoplay: 1,
    },
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable auto-scrolling
    autoplaySpeed: 4000, // Set auto-scroll speed in milliseconds
  };

  const apiUrl = "https://fresh-link.onrender.com";

  function fetchData() {
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Data received:", data);
        // Handle the data as needed
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors
      });
  }

  // Call fetchData every 10 seconds
  setInterval(fetchData, 10000);

  useEffect(() => {
    // Set the target date for the countdown (replace 'targetDate' with your desired date)
    const targetDate = new Date("2024-04-26T09:00:00").getTime();

    // Update the countdown every second
    const interval = setInterval(() => {
      // Get the current date and time
      const now = new Date().getTime();

      // Calculate the remaining time in milliseconds
      const distance = targetDate - now;

      // Calculate days, hours, minutes, and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Update the countdown state
      setCountdown(`${days}d\t: \t${hours}h\t: \t${minutes}m\t: \t${seconds}s`);

      // If the countdown is over, clear the interval
      if (distance < 0) {
        clearInterval(interval);
        setCountdown("First cohort ended");
      }
    }, 1000);

    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Header />
      {/* <section className="preloader">
                <div className="spinner">
                    <span className="sk-inner-circle"></span>
                </div>
            </section> */}

      <section className="slick-slideshow">
        <Slider {...settings} className="">

        {/* <div className="slick-custom">
            <img
              src="images/slideshow/2A1A1115.jpg"
              className="img-fluid"
              alt=""
            />

            <div className="slick-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-10">
                    <h3 className="slick-title text-danger">MOLEX Foundation Africa</h3>

                    <h6 className="slick-title text-dager mt-lg-3 mb-lg-5 show-">
                      We are committed to enriching lives through the provision
                      of life coaching, career guidance, mentoring, and advocacy
                      services.
                    </h6>
                    <Link to="/contact" className="btn custom-btn">
                      Get in touch
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

        <div className="slick-custom">
            <img src="images/sheroes/photo_2024-09-23_15-41-49.jpg" className="img-fluid" alt="" />

            <div className="slick-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-10">
                    <h2 className="slick-title">SHEROES in STEM</h2>
                    <h6 className="slick-title mt-lg-3 mb-lg-5 show-">
                      Empowering Girls through Artificial Intelligence and
                      Robotics
                    </h6>
                    <Link to="/contact" className="btn custom-btn">
                      Get in touch
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="slick-custom">
            <img src="images/sheroes/photo_2024-09-23_15-41-55.jpg" className="img-fluid" alt="" />

            <div className="slick-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-10">
                    <h2 className="slick-title">SHEROES in STEM</h2>
                    <h6 className="slick-title mt-lg-3 mb-lg-5 show-">
                      Empowering Girls through Artificial Intelligence and
                      Robotics
                    </h6>
                    <Link to="/contact" className="btn custom-btn">
                      Get in touch
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          

          <div className="slick-custom">
            <img src="images/molex/3M3A0113.jpg" className="img-fluid" alt="" />

            <div className="slick-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-10">
                    <h1 className="slick-title">MOLEX Foundation Africa</h1>

                    <h6 className="lead text-white mt-lg-3 mb-lg-5">
                      We are committed to enriching lives through the provision
                      of life coaching, career guidance, mentoring, and advocacy
                      services.
                    </h6>

                    <Link to="/about" className="btn custom-btn">
                      Learn more about us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="slick-custom">
            <img
              src="images/slideshow/2A1A1609.jpg"
              className="img-fluid"
              alt=""
            />

            <div className="slick-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-10">
                    <h1 className="slick-title">Women in STEM</h1>
                    <h6 className="slick-title mt-lg-3 mb-lg-5 show-">
                      We spike students' interest in Science Technology
                      Engineering and Mathematics (STEM) especially the
                      girl-child.
                    </h6>
                    <Link to="/contact" className="btn custom-btn">
                      Get in touch
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="slick-custom">
            <img
              src="images/slideshow/2A1A1383.jpg"
              className="img-fluid"
              alt=""
            />

            <div className="slick-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-10">
                    <h3 className="slick-title">Women in STEM</h3>
                    <h6 className="slick-title mt-lg-3 mb-lg-5 show-">
                      We spike students' interest in Science Technology
                      Engineering and Mathematics (STEM) especially the
                      girl-child.
                    </h6>
                    <Link to="/contact" className="btn custom-btn">
                      Get in touch
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          
        </Slider>
      </section>

      <section className="about section-padding">
        <div className="container border-colori">
          <div className="row border-colori">
            <div className="col-lg-12 col-12 border-colori">
              <div className="tab-content mt-2" id="pills-tabContent">
                <TabContent id="pills-home" active>
                  <div className="row border-color">
                    <div className="col-lg-6 col-12">
                      <img
                        src="images/molex/3M3A0115.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="d- flex-column justify-content-center h-100 ms-lg-4 mt-lg-0 mt-5">
                        <h4 className="mb-3">
                          <span> Impactful Approach</span>{" "}
                        </h4>
                        {/* <p>Little Fashion templates comes with <Link to="sign-in.html">sign in</Link> / <Link to="sign-up.html">sign up</Link> pages, product listing / product detail, about, FAQs, and contact page.</p> */}
                        <p className="b-text">
                          The MOLEX Foundation Africa stands as a stalwart
                          beacon of dedicated effort, committed to fostering the
                          growth and empowerment of children and young
                          individuals, with a special emphasis on those who find
                          themselves vulnerable and marginalized. At the heart
                          of our mission lies the fervent belief in not only
                          acknowledging their rights but also actively enabling
                          them to seize these rights and pave the way toward
                          equal opportunities for the pursuit of their
                          aspirations and hidden potentials.
                        </p>
                        <div className="mt-0 mt-lg-auto">
                          <Link
                            to="/about"
                            ref={coreValuesRef}
                            className="custom-link text-primary mb-2"
                          >
                            Learn more about us
                            <i className="bi-arrow-right ms-2"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <YouTubeEmbed videoId="k8x84hOB4P4" /> */}

      <section className="container-fluid activity-bg mb-5">
        <h2 className="container text-center mt-3 mb-3">
          <span className="text-center">Recent Activities</span>
        </h2>
        <div
          className="row container-"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div class="card" className="col-12 col-lg-3 activity" style={{}}>
            <img
              class="card-img-top"
              src="images/sheroes/photo_2024-09-23_15-42-20.jpg"
              alt="Card image"
            />
            <div class="card-body">
              <h6 class="card-title">SHEROES in STEM Bootcamp</h6>
              {/* <p class="card-text">Some example text.</p> */}
              {/* <a href="#" class="btn btn-primary">See Profile</a> */}
            </div>
          </div>

          <div class="card" className="col-12 col-lg-3 activity" style={{}}>
            <img
              class="card-img-top"
              src="images/prog/43_1.jpg"
              alt="Card image"
            />
            <div class="card-body">
              <h6 class="card-title text-center">
                SHEROES in STEM Facilitators' training
              </h6>
              {/* <p class="card-text">Some example text.</p> */}
              {/* <a href="#" class="btn btn-primary">See Profile</a> */}
            </div>
          </div>

          <Link to='/sheroes-in-stem' class="card" className="col-12 col-lg-3 activity" style={{}}>
            <img
              class="card-img-top"
              src="images/prog/pic01.jpeg"
              alt="Card image"
            />
            <div class="card-body">
              <h6 class="card-title">Launching of SHEROES in STEM</h6>
              {/* <p class="card-text">Some example text.</p> */}
              {/* <a href="#" class="btn btn-primary">See Profile</a> */}
            </div>
          </Link>
        </div>
      </section>

      <section className="team mt3">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mb-1">
              <h2 className="mb-2 mt-3">
                <span>Our Priorities</span>
              </h2>
            </div>
            <section className="team">
              <p className="mission-text mb-5">
                <div className="vision-text">
                  <p>
                    <em className="mdi mdi-checkbox-intermediate m-1"></em>
                    To mentor, coach, and guide children and young people,
                    ensuring equal access and opportunity for quality, inclusive
                    education for the girl-child.
                  </p>
                  <p>
                    <em className="mdi mdi-checkbox-intermediate m-1"></em>
                    Empowering Communities for Climate Change Mitigation
                    Strategies through Collective Action.
                  </p>
                  <p>
                    <em className="mdi mdi-checkbox-intermediate m-1"></em>
                    To improve living standards through entrepreneurship, skill
                    training, and community awareness.
                  </p>
                  {/* <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Combat against Sexual and Gender Based Violence.
                      </p> */}
                  <p>
                    <em className="mdi mdi-checkbox-intermediate m-1"></em>
                    To foster enthusiasm among students, particularly girls,
                    through initiatives such as SHEROES in STEM.
                  </p>
                  <p>
                    <em className="mdi mdi-checkbox-intermediate m-1"></em>
                    To create a safe environment for children and women against
                    all forms of violence, gender discrimination, and climate
                    change catastrophes.
                  </p>

                  {/* Add other core values */}
                </div>
              </p>
            </section>
          </div>
        </div>
      </section>

      {/*<Bannar/>*/}

      <section className="about section-paddking">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12 border-colori">
              <div className="tab-content mt-2" id="pills-tabContent">
                <TabContent id="pills-home" active>
                  <div className="row">
                    <div className="col-12">
                      <div className="d- flex-column justify-content-center h-100 ms-lg-4 mt-lg-0 mt-5">
                        <h4 className="text-center">
                          <span> World Menstrual Hygiene Day Programs</span>{" "}
                        </h4>

                        <header className="site-header container-fluid text-center row section-paddinog-img site-header-image">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="container-fluid column col-12"
        >
          <div className="container-fluid row col-12">
          <div style={{ fontWeight: "bold" }} className="my-4 h4">
                    {/* <em className="mdi mdi-checkbox-intermediate m-1"></em> */}
                    {/* Coding and Robotics Workshops */}
                  </div>
        
          </div>
         
        </div>
        <img
          src="images/prog/1.jpg"
          className="header-image border-top-left-radius  img-fluid col-12 col-lg-6"
          alt=""
        />
        <img
          src="images/prog/6.jpg"
          className="header-image  border-bottom-right-radius img-fluid col-12 col-lg-6"
          alt=""
        />
        {/* <img
          src="images/prog/15.jpg"
          className="header-image  img-fluid col-12 col-lg-6"
          alt=""
        />
        <img
          src="images/prog/12.jpg"
          className="header-image border-bottom-right-radius  img-fluid col-12 col-lg-6"
          alt=""
        /> */}
        <p className="my-4 text-dark">
                 
                  {/* Participants engage in hands-on coding exercises and robotics
                  training, learning programming languages like Python, and
                  micro-computer programming using ARDUINO. */}
                </p>
      </header>
                        {/* <p>Little Fashion templates comes with <Link to="sign-in.html">sign in</Link> / <Link to="sign-up.html">sign up</Link> pages, product listing / product detail, about, FAQs, and contact page.</p> */}
                        <p className="b-text">
                          {/* On Menstrual Health Day, Molex Foundation Africa collaborated with the Ghana Education Service in the Central Region to launch the 'Pad a Girl for Confidence' program. This initiative aims to provide menstrual health education and distribute sanitary products to young girls, fostering confidence and ensuring they can attend school without interruption. By addressing menstrual health needs, the program seeks to empower girls, promote gender equality, and support their educational journey, creating a positive impact on their future opportunities and overall well-being.. */}
                        </p>

                        <div className="mt-0 mt-lg-auto">
                          {/* <Link
                            to="/about"
                            ref={coreValuesRef}
                            className="custom-link text-primary mb-2"
                          >
                            Learn more about us
                            <i className="bi-arrow-right ms-2"></i>
                          </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section id="vid" className="about container-fluid d-flex section-paddking" style={{justifyContent:'center'}}>
        <div className="container row">
          <div className="col-md-6">
            <YouTubeEmbed
              videoId="k8x84hOB4P4"
              width={400}
              height={300}
              id="video1"
            />
          </div>
          <div className="col-md-6">
            <YouTubeEmbed
              videoId="Fs2k_LJh2D4"
              width={400}
              height={300}
              id="video2"
            />
          </div>
        </div>
      </section> */}

      <header className="site-header container-fluid text-center row section-paddinog-img site-header-image">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "centeri",
          }}
          className="container-fluid column col-12 col-lg-6"
        >
          <div className="container-fluid row col-12">
            <div className="col-12 ">
              <h2>
                <div className="text-primary">SHEROES in STEM</div>
                <span className="text-dark theme">
                  Empowering Girls Through Artificial Intelligence and Robotics
                </span>
              </h2>
              <Link
                to="/sheroes-in-stem"
                target="_blank"
                className="text-danger"
              >
                Read More <em className="mdi mdi-arrow-right-thin"></em>
              </Link>
            </div>
          </div>
          <div
            style={{ flexDirection: "column" }}
            className="container-fluid d-flex justify-content-end  col-12  mt-3"
          >
            <h3 className="text-">Camping 👇</h3>
            <div className="countdown">{countdown}</div>
          </div>
        </div>
        <img
          src="images/sheroes/photo_2024-09-23_15-42-02.jpg"
          className="header-image img-fluid col-12 col-lg-5 border-bottom-right-radius border-top-left-radius"
          alt=""
        />
      </header>

      <section className="featured-product section-padding">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="mb-5">
                <span>Our Projects</span>
              </h2>
            </div>

            <div className="col-lg-4 col-12 mb-3">
              <div className="product-thumb">
                <Link to="/program">
                  <img
                    src="images/prog/PINNACLE LOGO.png"
                    className="img-fluid product-image"
                    alt=""
                  />
                </Link>

                {/* <div className="product-top d-flex">
                                    <span className="product-alert me-auto">New Arrival</span>

                                    <Link to="#" className="bi-heart-fill product-icon"></Link>
                                </div> */}

                <div className="product-info d-flex">
                  <div>
                    <h5 className="product-title mb-0">
                      <Link href="/program" className="product-title-link">
                        The Pinnacle Journey
                      </Link>
                    </h5>

                    {/* <p className="product-p">Original package design from house</p> */}
                  </div>

                  {/* <small className="product-price text-faded ms-auto mt-auto mb-5">$25</small> */}
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-12 mb-3">
              <div className="product-thumb">
                <Link to="#">
                  <img
                    src="images/prog/GUIDING STAR LOGO.png"
                    className="img-fluid product-image"
                    alt=""
                  />
                </Link>

                {/* <div className="product-top d-flex">
                                    <span className="product-alert">Low Price</span>

                                    <Link to="#" className="bi-heart-fill product-icon ms-auto"></Link>
                                </div> */}

                <div className="product-info d-flex">
                  <div>
                    <h5 className="product-title mb-0">
                      <Link to="#" className="product-title-link">
                        Guiding Stars
                      </Link>
                    </h5>

                    {/* <p className="product-p">Costume Package</p> */}
                  </div>

                  {/* <small className="product-price text-faded ms-auto mt-auto mb-5">$35</small> */}
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-12">
              <div className="product-thumb">
                <Link to="#">
                  <img
                    src="images/prog/soon.jpg"
                    className="img-fluid product-image"
                    alt=""
                  />
                </Link>

                {/* <div className="product-top d-flex">
                                    <Link to="#" className="bi-heart-fill product-icon ms-auto"></Link>
                                </div> */}

                <div className="product-info d-flex">
                  <div>
                    <h5 className="product-title mb-0">
                      <Link to="#" className="product-title-link">
                        Time with MOLEX
                      </Link>
                    </h5>

                    {/* <p className="product-p">Nature made another world</p> */}
                  </div>

                  {/* <small className="product-price text-faded ms-auto mt-auto mb-5">$45</small> */}
                </div>
              </div>
            </div>

            <div className="col-12 text-center">
              <Link to="#" className="view-all">
                View All Programs
              </Link>
            </div>
          </div>
        </div>
      </section>

      <div className="container mt-3">
      
      {showToast && (
        <div className="toast show">
          <div className="toast-header">
            <strong className="me-auto"></strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="toast"
              onClick={handleClose}
            ></button>
          </div>
          <div className="toast-body">
            <video className="vid" autoPlay muted loop id="myVideo">
              <source
                src="https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fvideos%2FWhite%20and%20Blue%20Professional%20Modern%20Technology%20Pitch%20Deck%20Presentation%20(3).mp4?alt=media&token=a2f5041c-04b5-449f-a9b1-681f8dc9bd95"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}
    </div>

      <Footer />
    </div>
  );
}
