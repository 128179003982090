import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Molex = () => {
  return (
    <>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <Header />
      <div
      className="container"
        style={{
          position: "relative",
        //   background: 'red',
          width: "100%",
          height: 0,
          paddingTop: "56.25%",
          paddingBottom: 0,
          boxShadow: "0 2px 8px 0 rgba(63, 69, 81, 0.16)",
          marginTop: "1.6em",
          marginBottom: "0.9em",
          overflow: "hidden",
          borderRadius: "8px",
          willChange: "transform",
          display: 'flex,', justifyContent: 'center', alignItems: 'center'
        }}
      >
        <iframe
          loading="lazy"
          style={{
            position: "absolute",
            width: "100%",
            height: "70%",
            // background: 'red',
            top: 100,
            left: 0,
            border: "none",
            padding: 0,
            margin: 0,
          }}
          src="https://www.canva.com/design/DAGTLhKMNAw/6-nwob4jtbk-r5XgAsgisA/view?embed"
          allowFullScreen
          allow="fullscreen"
        ></iframe>
        
      </div>
      <a
        href="https://www.canva.com/design/DAGTLhKMNAw/6-nwob4jtbk-r5XgAsgisA/view?utm_content=DAGTLhKMNAw&utm_campaign=designshare&utm_medium=embeds&utm_source=link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <a href="files/MOLEX FOUNDATION AFRICA.pdf" download="MOLEX FOUNDATION AFRICA.pdf"class="btn btn-outline">Download file <em className="mdi mdi-cloud-download-outline"></em></a>
      </a>{" "}

      

     

    </div>
    <Footer/>
    </>
  );
};

export default Molex;
